@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap');

html,
body,
#root {
  margin: 0;
  padding: 0;
  height: 100%;
}

* {
  box-sizing: border-box;
  font-family: 'Open Sans';
}

*:focus:not(.focus-visible) {
  outline: none;
}

p {
  margin: 0;
}
